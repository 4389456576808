<template>
  <div>
    <head-layout
        head-title="维修记录"
        :head-btn-options="headBtnOptions"
        @head-add="headAdd"
    ></head-layout>
    <grid-layout
        ref="gridLayOut"
        :table-options="tableOptions"
        :table-data="tableData"
        :table-loading="tableLoading"
        :data-total="page.total"
        :page="page"
        @page-current-change="handleCurrentChange"
        @page-size-change="handleSizeChange"
        @page-refresh-change="onLoad"
        @gird-handle-select-click="selectChange"
    >
        <template #recordCode="{row,index}">
            <el-link type="primary" @click="rowView(row)">{{ row.recordCode }}</el-link>
        </template>
        <template #customBtn="{row,index}">
            <template v-if="row.eqRepairStatus == '0' && row.createUser == userInfo.user_id">
                <el-button type="text" @click="rowEdit(row)" v-if="permissionList.editBtn">编辑</el-button>
                <el-button type="text" @click="rowRemove(row)" v-if="permissionList.delBtn">删除</el-button>
            </template>
            <template v-else>
                <el-button type="text" @click="rowView(row)" v-if="permissionList.viewBtn">查看</el-button>
            </template>
        </template>
    </grid-layout>
  </div>
</template>

<script>
import HeadLayout from '@/views/components/layout/head-layout.vue'
import GridLayout from "@/views/components/layout/grid-layout.vue";
import { mapGetters } from 'vuex';
import {serviceRecordPage,serviceRecordRemove} from '@/api/equipmentFacilities/equipmentMaintenance'

export default {
    components: {
        HeadLayout,
        GridLayout,
    },

    computed: {
        ...mapGetters(["permission", 'colorName','userInfo']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.equipmentMaintenance_service_add, true),
                viewBtn: this.vaildData(this.permission.equipmentMaintenance_service_view, true),
                editBtn: this.vaildData(this.permission.equipmentMaintenance_service_edit, true),
                delBtn: this.vaildData(this.permission.equipmentMaintenance_service_delete, true),
            };
        },

        headBtnOptions () {
            let btnList = []
            if(this.permissionList.addBtn){
                btnList.push({
                    label: this.$t(`cip.cmn.btn.addBtn`),
                    emit: "head-add",
                    type: "button",
                    icon: ""
                })
            }
            return this.$route.query.type=='view' ? [] :btnList
        },

        tableOptions () {
            return {
                selection: false,
                menu: true,
                linklabel: 'recordCode',
                column: [
                    {
                        label: '记录编号',
                        prop: 'recordCode',
                        align: 'center',
                        overHidden: true,
                        slot: true
                    },
                    {
                        label: '维修内容',
                        prop: 'content',
                        align: 'center',
                        overHidden: true,
                    },
                    {
                        label: '维修日期',
                        prop: 'repairDate',
                        align: 'center',
                        overHidden: true,
                    },
                    {
                        label: '状态',
                        prop: "eqRepairStatus",
                        type: 'select',
                        align: "center",
                        dicUrl:"/api/sinoma-system/dict-biz/dictionary?code=eq_repair_status",
                        props: {
                            label: "dictValue",
                            value: "dictKey",
                        },
                    },
                ]
            } 
        }
    },

    data () {
        return {
            tableData: [],
            tableLoading: false,
            page: {
                pageSize: 20,
                currentPage: 1,
                total: 0
            },
            query: {},
            selectionList: [],
            formType: '',
            typeId: '',
            equipmentLedgerId: '',
        }
    },

    // created () {
    //     this.onLoad(this.page)
    // },

    methods: {
      init(type, typeId, id) {
          this.formType = type;
          this.typeId = typeId;
          this.equipmentLedgerId = id;
          this.onLoad(this.page, {});
        },

        headAdd () {
            this.$router.push({
                path: '/equipmentFacilities/equipmentMaintenance/service/form',
                query: {
                    row: JSON.stringify({
                      equipmentId: this.equipmentLedgerId,
                      equipmentName: this.$route.query.name,
                      equipmentCode: this.$route.query.code,
                      orgId: this.$route.query.orgId,
                      orgCode: this.$route.query.orgCode
                    }),
                    type: 'add'
                }
            })
        },

        onLoad (page,params={}){
            this.tableLoading = true
            this.page = page
            serviceRecordPage(page.currentPage, page.pageSize,{equipmentId: this.equipmentLedgerId}).then(res=>{
                const data = res.data.data;
                this.$refs.gridLayOut.page.total = data.total;
                this.tableData = data.records ? data.records.map(val=>{
                    val.status = val.status.toString()
                    return val
                }) : []
                this.tableLoading = false;
                // this.$refs.gridLayOut.selectionClear()
                this.$nextTick(() => {
                    this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
                    this.$refs.gridLayOut.$refs.grid.refreshTable();
                });
            })
        },

        //页数改变
        handleCurrentChange(e) {
            this.page.currentPage = e.currentPage
            this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
        },
        //size改变
        handleSizeChange(e) {
            this.page.pageSize = e.pageSize
            this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
        },

        selectChange (list) {
            this.selectionList = list
        },

        rowEdit (row) {
            this.$router.push({
                path: '/equipmentFacilities/equipmentMaintenance/service/form',
                query: {
                    row: JSON.stringify({ id: row.id }),
                    type: 'edit'
                }
            })
        },
        
        rowRemove (row){
            this.$confirm("确认删除当前数据吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                serviceRecordRemove(row.id).then((res) => {
                    if (res.data.code == 200) {
                        this.$message.success("删除成功")
                        this.onLoad(this.page);
                    }
                });
            }).catch((error) => {
                this.$message({
                    message: "取消删除",
                    type: "info",
                });
            });
        },

        rowView (row) {
            this.$router.push({
                path: '/equipmentFacilities/equipmentMaintenance/service/form',
                query: {
                    row: JSON.stringify({ id: row.id }),
                    type: 'view'
                }
            })
        }
    }
}
</script>

<style>

</style>