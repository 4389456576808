<template>
  <div>
    <head-layout
      head-title="检查记录"
      :head-btn-options="headBtnOptions"
      @head-add="headAdd"
    ></head-layout>

    <grid-layout
      ref="gridLayOut"
      :table-options="tableOption"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @gird-handle-select-click="selectionChange"
      >
        <template #taskCode="{row}">
          <el-link type="primary" @click="rowView(row)">{{ row.taskCode }}</el-link>
        </template>
        <template #taskStatus="{row}">
          <span :class="row.taskStatus=='PREPARE' ? 'taskStatusPend' : ''">{{ getDicValue(statusList, row.taskStatus) }}</span>
        </template>
        <template #lineStatus="{row}">
          <div class="lineStatus-box">
              <div :class="row.lineStatus == 'ERROR' ? 'lineStatus-error' : 'lineStatus-normal'" >
              {{ row.lineStatus == 'ERROR' ? '是' : '否'}}
              </div>
          </div>
        </template>
    </grid-layout>

  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import { mapGetters } from "vuex";
import * as API from "@/api/equipmentFacilities/equipmentLedger";
import { taskRecord } from '@/api/equipmentFacilities/equipmentInspection'
import {getDictionaryCode} from "@/api/system/dictbiz";

export default {
  components: {
    GridLayout,
    HeadLayout,
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    headBtnOptions() {
      return [
        {
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-add",
          type: "button",
          icon: "",
        },
        // {
        //   label: this.$t("cip.cmn.btn.delBtn"),
        //   emit: "head-romve",
        //   type: "button",
        //   icon: "",
        // },
      ];
    },

    tableOption () {
            return {
              linklabel: 'taskCode',
                selection: false,
                menu: false, 
                column: [
                    {
                        label: '任务编号',
                        prop: 'taskCode',
                        align: "center",
                        overHidden: true,
                        slot: true,
                    },
                    {
                        label: '检查类型',
                        prop: 'checkTypeName',
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: '检查日期',
                        prop: 'checkTime',
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: '检查人',
                        prop: 'userName',
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: '任务状态',
                        prop: 'taskStatus',
                        align: "center",
                        overHidden: true,
                        // type: 'select',
                        // dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=CHECK_TASK_STATUS`,
                        // props: {
                        //     label: 'dictValue',
                        //     value: 'dictKey',
                        // },
                        slot: true,
                    },
                    {
                        label: '是否异常',
                        prop: 'lineStatus',
                        align: "center",
                        overHidden: true,
                        slot: true
                    },
                ]
            }
        }

  },
  data() {
    return {
      tableData: [],
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      equipmentLedgerId: "",
      statusList: [],
      detailForm: {}
    };
  },
  mounted() {},
  methods: {
    init(type, typeId, id, detailForm) {
      this.formType = type;
      this.typeId = typeId;
      this.equipmentLedgerId = id;
      this.detailForm = detailForm
      this.getDictionaryCode()
      this.onLoad(this.page, {});
    },

    // 获取列表数据
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      taskRecord(page.currentPage,page.pageSize,
        {
          objectType: 'EQ',
          objectCode: this.$route.query.code,
          organizationId: this.userInfo.dept_id,
          // equipmentId: this.equipmentLedgerId,
        ...params
      }).then(res => {
          const data = res.data.data;
          this.$refs.gridLayOut.page.total = data.total;
          this.tableData = data.records;
          this.tableLoading = false;
          this.$refs.gridLayOut.selectionClear()
      });
    },
    // 选择列表数据
    selectionChange(list) {
      this.selectionList = list;
    },

    rowView (row) {
      this.$router.push({
          path: `/hiddenTrouble/check/checkTasks/edit`,
          query: {
          actId: row.actId,
          id: row.id,
          type: 'view',
          }
      })
    },

    getDictionaryCode() {
        getDictionaryCode("CHECK_TASK_STATUS").then((res) => {
            this.statusList = res.data.data;
        });
    },

    getDicValue (dic, key) {
        let data = dic.find(val => val.dictKey == key)
        return data ? data.dictValue : ''
    },

    headAdd(){
        this.$router.push({
            path: `/hiddenTrouble/check/daily/edit`,
            query: {
                type: 'add',
                checkType: 3,
                equipmentType: this.detailForm.typeId,
                equipmentCode: this.detailForm.equipmentCode,
                equipmentName: this.detailForm.equipmentName,
            }
        })
    },
  },
};
</script>
<style scoped lang="scss">
.lineStatus-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  .lineStatus-normal {
    border: 1px solid #67c23a;
    color: #67c23a;
    display: inline-block;
    width: 60px;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
  }
  .lineStatus-error {
    border: 1px solid rgb(197, 33, 27);
    color: rgb(197, 33, 27);
    display: inline-block;
    width: 60px;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
  }
}
.taskStatusPend{
  color: rgb(197, 33, 27);
}
</style>
