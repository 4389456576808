<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      head-title="我的知识"
      @head-upload="headUpload()"
    ></head-layout>
    <div class="main-content">
      <el-tabs
        class="main-tab"
        tab-position="left"
        type="border-card"
        v-model="activeName"
        @tab-click="handleClick"
      >
        <el-tab-pane name="myUpload">
          <span slot="label"><i class="iconfont icon-shangchuan"></i> 我的上传</span>
          <myUpload v-if="activeName == 'myUpload'" />
        </el-tab-pane>
        <el-tab-pane name="myCollect">
          <span slot="label"><i class="iconfont icon-shoucang"></i> 我的收藏</span>
          <myCollect v-if="activeName == 'myCollect'" />
        </el-tab-pane>
        <el-tab-pane name="myShare">
          <span slot="label"><i class="iconfont icon-fenxiang"></i> 我的分享</span>
          <myShare v-if="activeName == 'myShare'" />
        </el-tab-pane>
        <el-tab-pane name="browsingHistory">
          <span slot="label"><i class="iconfont icon-lishijilu"></i> 浏览历史</span>
          <browsingHistory v-if="activeName == 'browsingHistory'" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import myUpload from "./myUpload";
import myShare from "./myShare";
import myCollect from "./myCollect";
import browsingHistory from "./browsingHistory";
import HeadLayout from "@/views/components/layout/head-layout";
export default {
  data() {
    return {
      activeName: "myUpload",
    };
  },

  components: {
    myUpload,
    myShare,
    myCollect,
    browsingHistory,
    HeadLayout,
  },

  computed: {
    headBtnOptions() {
      return [
        {
          label: "知识上传",
          emit: "head-upload",
          type: "button",
          icon: "",
          btnOptType: "primary",
        },
      ];
    },
  },

  mounted() {
    if (this.$route.query.type) {
      this.activeName = this.$route.query.type;
    }
  },

  methods: {
    headUpload() {
      this.$router.push({
        path: `/knowledge/knowledgeUpload`,
        query: {
          type: "add",
        },
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.head-search {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border: 1px solid #ccc;
  justify-content: space-between;
  font-size: 14px;
  background: #fff;
  span {
    width: 200px;
  }
}
.main-content {
  height: calc(100vh - 170px);
  background: #fff;
  padding-top: 4px;
  ::v-deep .el-tabs {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  ::v-deep .el-tabs__header {
    height: calc(100% - 1px) !important;
  }

  ::v-deep .el-tabs--border-card {
    background-color: #F5F7FA;
  }
  ::v-deep .el-tabs__content {
    padding: 0;
  }
  ::v-deep .el-tabs--left .el-tabs__header.is-left{
    margin-right: 0;
  }
  ::v-deep .el-tabs--left.el-tabs--border-card .el-tabs__item.is-left{
    margin: 0 10px;
    border: none !important;
  }
  ::v-deep .el-tabs__nav-scroll {
    background: #fff;
    padding-top: 15px;
    margin-right: 10px;
    width: 180px;
  }
  ::v-deep .el-tabs--left.el-tabs--border-card .el-tabs__item.is-left.is-active {
    border: none;
    background: #e2e5ea;
    border-radius: 6px;
  }
  ::v-deep .el-tabs--left .el-tabs__item.is-left{
    text-align: left;
  }
  ::v-deep .el-tabs__item .iconfont{
    font-size: 18px;
    margin-right: 10px;
  }
}
</style>
