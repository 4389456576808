<!--
 * @Author: absorbedyy@163.com
 * @Date: 2024-05-24 13:56:17
 * @LastEditors: absorbedyy@163.com
 * @LastEditTime: 2024-08-16 13:44:06
 * @Description:
-->
<template>
  <div class="ledger-form">
    <el-tabs
      tab-position="left"
      type="border-card"
      v-model="activeName"
      @tab-click="handleClick"
    >
      <el-tab-pane label="基本信息" name="baseInfo">
        <baseInfo
          v-if="activeName == 'baseInfo'"
          @addCallBack="addCallBack"
          ref="baseInfo"
        />
      </el-tab-pane>
      <template v-if="equipmentLedgerId || currentId">
        <el-tab-pane label="证书资料" name="certificateInfo">
          <certificateInfo
            v-if="activeName == 'certificateInfo'"
            ref="certificateInfo"
          />
        </el-tab-pane>
        <el-tab-pane label="检查记录" name="checkRecord">
          <checkRecord v-if="activeName == 'checkRecord'" ref="checkRecord" />
        </el-tab-pane>
        <el-tab-pane label="保养记录" name="maintainRecord">
          <maintainRecord
            v-show="activeName == 'maintainRecord'"
            ref="maintainRecord"
          />
        </el-tab-pane>
        <el-tab-pane label="维修记录" name="serviceRecord">
          <serviceRecord
            v-show="activeName == 'serviceRecord'"
            ref="serviceRecord"
          />
        </el-tab-pane>
      </template>
      <template v-if="specialFlag == 1 && (equipmentLedgerId || currentId)">
        <el-tab-pane label="检验记录" name="testRecord">
          <testRecord v-if="activeName == 'testRecord'" ref="testRecord" />
        </el-tab-pane>
      </template>
    </el-tabs>
  </div>
</template>

<script>
import baseInfo from "./tabPage/baseInfo";
import certificateInfo from "./tabPage/certificateInfo";
import checkRecord from "./tabPage/checkRecord";
import maintainRecord from "./tabPage/maintainRecord";
import serviceRecord from "./tabPage/serviceRecord";
import testRecord from "./tabPage/testRecord.vue";
export default {
  components: {
    baseInfo,
    certificateInfo,
    checkRecord,
    maintainRecord,
    serviceRecord,
    testRecord,
  },
  data() {
    return {
      activeName: "baseInfo",
      formType: "",
      typeId: "",
      currentId: "", // 当前数据id
      equipmentLedgerId: "", // 基本信息新增的时候生成的id
      specialFlag: "", // 是否为特种设备 1 则是  特种设备多一个检验记录的tab
      prjData: {}, // 当前所属项目
      detailForm: {},
    };
  },

  mounted() {
    let type = this.$route.query.type;
    let typeId = this.$route.query.typeId;
    this.prjData = {
      id: this.$route.query.prjId,
      code: this.$route.query.prjCode,
    }
    this.currentId = this.$route.query.id;

    this.formType = type;
    if (this.formType == "edit" || this.formType == "view") {
      this.specialFlag = this.$route.query.specialFlag;
    }
    this.typeId = typeId;
    this.$refs[this.activeName].init(
      this.formType,
      this.typeId,
      this.formType == "add" ? this.equipmentLedgerId : this.currentId,
      {...this.prjData}
    );
  },
  methods: {
    handleClick() {
      this.$nextTick(()=>{
        this.$refs[this.activeName].init(
          this.formType,
          this.typeId,
          this.formType == "add" ? this.equipmentLedgerId : this.currentId,
          {...this.detailForm}
        );
      })
    },

    addCallBack(data) {
      this.equipmentLedgerId = data.id;
      this.specialFlag = data.specialFlag;
      this.detailForm = data
    },
  },
};
</script>
<style scoped lang="scss">
.ledger-form {
  width: 100%;
  height: 100%;
  ::v-deep .el-tabs {
    width: 100%;
    height: 100%;
  }
  ::v-deep .el-tabs__header.is-left {
    min-width: 130px !important;
  }
  ::v-deep .el-tabs--border-card {
    background-color: #f5f7fa;
  }
  ::v-deep .el-tabs__content {
    height: 100%;
    overflow-y: auto;
  }
}
</style>
