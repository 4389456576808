<template>
  <div style="background: #fff">
    <el-tabs v-model="activeName" @tab-click="handleClick" class="upload-tab">
      <el-tab-pane label="全部" name="all">
        <grid-layout
          ref="gridLayOut"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @grid-row-detail-click="rowView"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
        >
          <template #customBtn="{ row }">
<!--            <el-button-->
<!--              v-if="row.status == 2 || row.status == 3"-->
<!--              type="text"-->
<!--              size="medium"-->
<!--              @click="rowView(row)"-->
<!--            >-->
<!--              查看-->
<!--            </el-button>-->
<!--            <el-button
              v-if="row.status == 2 || row.status == 3 || row.status == 4"
              type="text"
              size="medium"
              @click="handleFlow(row)"
            >
              流程图
            </el-button>-->
            <el-button
              v-if="row.status == 2 || row.status == 3 || row.status == 4"
              type="text"
              size="medium"
              @click="headProgress(row)"
            >
              流程进度
            </el-button>
            <el-button
              v-if="row.status == 1"
              type="text"
              size="medium"
              @click="rowEdit(row)"
            >
              编辑
            </el-button>
            <el-button
              type="text"
              size="medium"
              @click="deleteUpload(row)"
              v-if="row.status == 1"
            >
              删除
            </el-button>
          </template>
        </grid-layout></el-tab-pane
      >
      <el-tab-pane label="已审核" name="audit">
        <grid-layout
          ref="gridLayOut"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @grid-row-detail-click="rowView"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
        >
          <template #customBtn="{ row }">
<!--            <el-button-->
<!--              v-if="row.status == 2 || row.status == 3"-->
<!--              type="text"-->
<!--              size="medium"-->
<!--              @click="rowView(row)"-->
<!--            >-->
<!--              查看-->
<!--            </el-button>-->
<!--            <el-button
              v-if="row.status == 2 || row.status == 3 || row.status == 4"
              type="text"
              size="medium"
              @click="handleFlow(row)"
            >
              流程图
            </el-button>-->
            <el-button
              v-if="row.status == 2 || row.status == 3 || row.status == 4"
              type="text"
              size="medium"
              @click="headProgress(row)"
            >
              流程进度
            </el-button>
          </template>
        </grid-layout>
      </el-tab-pane>
      <el-tab-pane label="审核中" name="auditing">
        <grid-layout
          ref="gridLayOut"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @grid-row-detail-click="rowView"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
        >
          <template #customBtn="{ row }">
<!--            <el-button-->
<!--              v-if="row.status == 2"-->
<!--              type="text"-->
<!--              size="medium"-->
<!--              @click="rowView(row)"-->
<!--            >-->
<!--              查看-->
<!--            </el-button>-->
<!--            <el-button-->
<!--              v-if="row.status == 2 || row.status == 3 || row.status == 4"-->
<!--              type="text"-->
<!--              size="medium"-->
<!--              @click="handleFlow(row)"-->
<!--            >-->
<!--              流程图-->
<!--            </el-button>-->
            <el-button
              v-if="row.status == 2 || row.status == 3 || row.status == 4"
              type="text"
              size="medium"
              @click="headProgress(row)"
            >
              流程进度
            </el-button>
            <el-button
              type="text"
              v-if="row.status == 2 || row.status == 3 || row.status == 4"
              @click="rowTrackPending(row)"
            > 详情
            </el-button>
          </template>
        </grid-layout>
      </el-tab-pane>
      <el-tab-pane label="未审核" name="unAudit">
        <grid-layout
          ref="gridLayOut"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @grid-row-detail-click="rowView"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
        >
          <template #customBtn="{ row }">
            <el-button
              v-if="row.status == 1"
              type="text"
              size="medium"
              @click="rowEdit(row)"
            >
              编辑
            </el-button>
            <el-button
              type="text"
              size="medium"
              @click="deleteUpload(row)"
              v-if="row.status == 1"
            >
              删除
            </el-button>
          </template>
        </grid-layout>
      </el-tab-pane>
    </el-tabs>

    <CommonDialog v-if="processVisible" width="70%" dialogTitle="流程进度" @cancel="processVisible = false" :showBtn="false" >
      <bpmn-flow ref="bpmnFlow" :bpmnOption="bpmnOption" :flow="flow"></bpmn-flow>
    </CommonDialog>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { detail } from "@/api/plugin/workflow/process";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import GridLayout from "@/views/components/layout/grid-layout";
import WfFlow from '@/views/plugin/workflow/process/components/flow.vue';
import { listInfo, removeUpload } from "@/api/knowledgeClassifcation/knowledge";
import {dictionaryBiz} from "@/api/reportTasks";
import website from "@/config/website";
import CommonDialog from "@/components/CommonDialog";
import bpmnFlow from "@/views/plugin/workflow/components/process-bpmn-flow/index.vue";
export default {
  components:{ GridLayout, WfFlow, CommonDialog,
    bpmnFlow},
  mixins: [exForm],
  data() {
    return {
      processVisible: false,
      activeName: 'all',
      circulationVisible: false,
      bpmnOption: {},
      bpmnVisible: false,
      tableData: [],
      planStatus:[],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      statusList: "",
    };
  },

  computed: {
    ...mapGetters(["permission","language"]),
    tableOptions() {
      return {
        index: true,
        indexLabel: "序号",
        menuWidth: 220,
        selection: false,
        linklabel: "fileName",
        column: [
          {
            label: "名称",
            align: "left",
            prop: "fileName",
            overHidden: true,
          },
          {
            label: "文档类型",
            align: "center",
            prop: "extension",
            width: 120,
          },
          {
            label: "上传时间",
            align: "center",
            prop: "updateTime",
            width: 220,
          },
          {
            label: "状态",
            align: "center",
            prop: "status",
            width: 120,
            dicData:[],
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dataType: "number",
          },
        ],
      };
    },
  },

  mounted() {
    this.onLoad(this.page);
    dictionaryBiz("is_training_plan_status").then((res) => {
      const column = this.findObject(this.tableOptions.column, "status");
      column.dicData = res.data.data;
    });
  },

  methods: {
    async rowTrackPending(row) {
      const {processInstanceId} = row;
      detail({processInsId: processInstanceId}).then((res) => {
        const {process} = res.data.data;
        const {id, taskId, processInstanceId, processId, processDefKey} =
          process;
        let param = Buffer.from(
          JSON.stringify({
            processId: id,
            taskId,
            processInsId: processInstanceId || processId,
            processDefKey,
          })
        ).toString("base64");
        //先直接跳转安环侧流程详情页面，因为平台侧页面跳转会出现导航，安环系统已经去除导航
        let sUrl =
          window.location.origin + "#/process/external/iframe/detail?p=" + param;
        window.open(sUrl, "_blank");
      });
    },
    headProgress(row) {
      const {taskId, processInstanceId} = row
      detail({taskId, processInsId: processInstanceId}).then(res => {
        const {process, flow} = res.data.data
        this.bpmnOption = {
          mode: 'view',
          xml: process.xml,
          flows: this.handleResolveFlows(flow),
          lang: this.language
        }
        this.process = process
        this.flow = flow
        this.processVisible = true
      })
    },
    // 查看流程图
    handleFlow (row) {
      const { taskId, processInstanceId } = row
      detail({ taskId, processInsId: processInstanceId }).then(res => {
        const { process, flow } = res.data.data

        this.bpmnOption = {
          mode: 'view',
          xml: process.xml,
          flows: this.handleResolveFlows(flow),
          lang: this.language
        }

        this.bpmnVisible = true
      })
    },
    handleClick(tab) {
      if (tab.name == "audit") {
        this.statusList = "3";
      } else if (tab.name == "unAudit") {
        this.statusList = "1";
      } else if (tab.name == "auditing") {
        this.statusList = "2,4";
      } else {
        this.statusList = "";
      }
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    onLoad(page) {
      this.page = page;
      this.tableLoading = true;
      listInfo(
        page.currentPage,
        page.pageSize,
        this.fileName,
        this.statusList
      ).then((res) => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    },
    deleteUpload(row) {
      removeUpload(row.id).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.onLoad(this.page);
        }
      });
    },
    rowEdit(row) {
      this.$router.push({
        path: `/knowledge/knowledgeUpload`,
        query: {
          id: row.id,
          type: "edit",
        },
      });
    },
    rowView(row) {
      window.open(window.location.origin + `#/knowledgeBrowse?id=${row.id}&fileName=${row.fileName}`);
      // window.open(`http://localhost:1888/#/knowledgeBrowse?id=${row.id}&fileName=${row.fileName}`);
    },
  },
};
</script>

<style scoped lang='scss'>
::v-deep .el-tabs__header {
  padding-left: 12px;
  margin-bottom: 8px;
}
::v-deep .el-tabs__content {
  padding: 0 !important;
}
::v-deep .bjs-powered-by {
  display: none;
}
::v-deep .upload-tab .el-tabs__nav-scroll {
    padding-top: 0 !important;
    width: 100% !important;
  }
</style>
